<!--
 * @Description:公众号 个人信息 selfInfo
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-04 14:47:11
 * @LastEditors: zhoucheng
-->
<template>
  <div class="body">
    <van-row class="top">
      <van-row class="image"
               style="margin-left: 4.27%;">
        <van-uploader :after-read="uploadAvatar">
          <van-image class="head"
                     fit="cover"
                     :src="userInfo.avatar? $headPhotoUrl+userInfo.avatar : require('@/assets/wxSelfInfo/head.png')" />
        </van-uploader>
      </van-row>
      <van-row class="name">{{userInfo.userName}}</van-row>
      <van-row class="right"
               @click="handleClickSelfInfo"> > </van-row>
    </van-row>

    <van-row class="takeNotes">
      <van-col class="stopNotes"
               @click="handleClickParkRecord">
        <div>停车记录</div>
      </van-col>
      <van-col class="payNotes"
               @click="handleClickPayRecord">
        <div>缴费记录</div>
      </van-col>
    </van-row>

    <van-row class="dt-secondLine">
      <van-row class="dt-line">
        <van-col :span="10"
                 class="myCar">我的车辆</van-col>
        <van-col :span="13"></van-col>
        <van-col :span="1">
        </van-col>
      </van-row>
      <van-row v-for="(item,index) in plateNumberList"
               :key="index">
        <van-row class="dt-line_car">
          <van-row class="myCarImg">
            <img v-if="Number(item.numberPlateColorCode)===2"
                 :src="require('@/assets/wxSelfInfo/blueCard.png')">
            <img v-else-if="Number(item.numberPlateColorCode)===6"
                 :src="require('@/assets/wxSelfInfo/greenCard.png')">
            <img v-else-if="Number(item.numberPlateColorCode)===3"
                 :src="require('@/assets/wxSelfInfo/yellowCard.png')">
          </van-row>
          <van-row class="info">
            <div class="plateNumberClass">{{item.plateNumber}}</div>
            <div class="colorClass">{{item.plateColor}}</div>
          </van-row>
          <van-row class="remove">
            <van-button type="danger"
                        class="remove_button"
                        @click="removeButton(item)"
                        round>解除绑定</van-button>
          </van-row>
        </van-row>
      </van-row>
      <van-row class="dt-line_binDing"
               v-show="bindButtonShow">
        <van-col class="binDing">
          <van-button plain
                      hairline
                      type="info"
                      round
                      class="binDingButton"
                      @click="handleBindPlateNumber">+ 去绑定</van-button>
        </van-col>
      </van-row>
    </van-row>
    <van-row class="dt-secondLine2">
      <van-row class="dt-line">
        <van-col class="feedBack">意见反馈</van-col>
        <van-col class="img"
                 @click="feedBackButton">
          <img src="@/assets/wxSelfInfo/right.png"
               class="img"
               alt="">
        </van-col>
      </van-row>
      <van-row class="dt-line">
        <van-col class="feedBack">客服电话</van-col>
        <van-col class="phone">
          400-110-1699
        </van-col>
      </van-row>
      <van-row class="bottom">
        <van-col class="words">支持公众号聊天窗口留言</van-col>
      </van-row>
    </van-row>

  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import getUserInfo from '@/utils/getUserInfo.js'
import Compressor from 'compressorjs'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      userInfo: {},
      plateNumberList: [],
      bindButtonShow: false
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    getUserInfo().then(() => {
      this.getUserInfoDetails()
      this.queryPlateNumber()
    })
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 停车记录
    handleClickParkRecord () {
      this.$router.push({ name: 'selfParkRecord' })
    },
    // 缴费记录
    handleClickPayRecord () {
      this.$router.push({ name: 'selfPayRecord' })
    },
    // 意见反馈
    feedBackButton () {
      this.$router.push({ name: 'selfAdviseFeedBack' })
    },
    // 获取绑定车牌
    queryPlateNumber () {
      this.$bindPlateNumber.queryBindPlateNumber({}).then(response => {
        this.plateNumberList = response.resultEntity
        if (this.plateNumberList.length >= 3) {
          this.bindButtonShow = false
        } else {
          this.bindButtonShow = true
        }
      })
    },
    // 解除绑定
    removeButton (item) {
      this.$dialog.confirm({
        title: '提示',
        message: '确认解绑该车牌吗？'
      }).then(() => {
        const info = {
          plateNumber: item.plateNumber
        }
        this.$bindPlateNumber.unBindPlateNumber(info).then(res => {
          if (res.resultCode === '2000') {
            this.$toast.success('解绑成功')
            this.queryPlateNumber()
          }
        })
      })
    },
    // 去绑定
    handleBindPlateNumber () {
      this.$router.push({ name: 'selfInfoBindPlate' })
    },
    // 个人信息
    getUserInfoDetails () {
      this.$selfInfo.getUserInfo({}).then(res => {
        this.userInfo = res.resultEntity
        if (!this.userInfo.phoneNumber) {
          this.$dialog.confirm({
            title: '提 示',
            message: '请您先绑定手机号再进行操作，否则将无法享受完整服务。'
          }).then(() => {
            this.$router.push({
              name: 'selfChangePhone',
              query: { phoneNumber: this.userInfo.phoneNumber }
            })
          }).catch(() => {
            this.$toast('您已取消')
          })
        }
      })
    },
    // 点击详情
    handleClickSelfInfo () {
      this.$router.push({ name: 'selfInfoDetail' })
    },
    // 头像上传
    uploadAvatar (file) {
      // 压缩图片
      const self = this
      /* eslint-disable-next-line no-new */
      new Compressor(file.file, {
        quality: 0.2,
        success (result) {
          // 将 Blob 对象转换成 File 对象
          result = new window.File([result], result.name, { type: result.type })
          const formData = new FormData()
          formData.append('file', result)
          self.$selfInfo.uploadAvatar(formData).then(res => {
            const avatar = res.resultEntity
            const info = {
              userCode: self.userCode,
              avatar
            }
            self.$selfInfo.changeUserInfo(info).then(res => {
              self.getUserInfoDetails()
              self.$toast.success('修改成功')
            }).catch(() => {
              self.$toast.fail('操作失败')
            })
          }).catch(err => {
            console.log('头像上传失败', err)
          })
        },
        error (err) {
          console.log('压缩失败', err)
        }
      })
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  .top {
    height: 280px;
    background: #ffffff;
    border-radius: 0px;
    background: url('~@/assets/wxSelfInfo/brckground.png');
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    position: relative;
    .image .head {
      width: 88px;
      height: 88px;
      border-radius: 50%;
      overflow: hidden;
    }
    .name {
      height: 25px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 25px;
      color: #ffffff;
      margin-left: 2.67%;
    }
    .right {
      width: 8px;
      height: 16px;
      color: #ffffff;
      position: absolute;
      right: 5.6%;
    }
  }
  .takeNotes {
    width: 100%;
    position: absolute;
    top: 208px;
    .stopNotes {
      width: 44.27%;
      height: 100px;
      background: url('~@/assets/wxSelfInfo/stop.png');
      background-size: 100% 100%;
      margin-left: 4.27%;
    }
    .stopNotes div {
      margin: 39px 0 0 6.02%;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: white;
    }
    .payNotes {
      width: 44.27%;
      height: 100px;
      background: url('~@/assets/wxSelfInfo/pay.png') no-repeat;
      background-size: 100% 100%;
      margin-left: 2.67%;
    }
    .payNotes div {
      margin: 39px 0 0 6.02%;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: white;
    }
  }

  .dt-secondLine {
    margin-top: 39px;
    height: 305px;
    background-color: #ffffff;
    .dt-line {
      margin-left: 5.33%;
      .myCar {
        height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 22px;
        color: #333333;
        margin-top: 20px;
      }
    }
    .dt-line_car {
      width: 91.6%;
      height: 84px;
      border-bottom: 1px solid #f1f3f7;
      margin-left: 4.2%;
      display: flex;
      align-items: center;
      position: relative;
      .myCarImg {
        width: 44px;
        height: 44px;
      }
      .info {
        height: 44px;
        margin-left: 2.67%;
        .plateNumberClass {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
        }
        .colorClass {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          padding-top: 5px;
          color: #999999;
        }
      }
      .remove {
        position: absolute;
        right: 3.7%;
        .remove_button {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          background: #ff6c76;
        }
      }
    }
    .dt-line_binDing {
      width: 100%;
      .binDing {
        width: 100%;
        height: 83px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .binDingButton {
        height: 28px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 0px;
        color: #045de2;
      }
    }

    .dt-line_car1 {
      margin: 20px 0 18px 5.33%;
      width: 100%;
    }
  }
  .dt-secondLine2 {
    margin-top: 7px;
    height: 192px;
    background-color: #ffffff;
    .dt-line {
      padding-left: 5.33%;
      border-bottom: 1px solid #f1f3f7;
      display: flex;
      .feedBack {
        margin: 20px 0;
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #333333;
      }
      .img {
        width: 8px;
        height: 16px;
        margin-top: 12px;
        position: absolute;
        right: 5.6%;
      }
      .phone {
        margin-top: 20px;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #666666;
        position: absolute;
        right: 5.6%;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      .words {
        text-align: center;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #666666;
      }
    }
  }
}
</style>
